import { ChainId, JSBI, Percent, Token, WETH } from '@cryptoguysclub/lizardsdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected, uauth, walletconnect, walletlink } from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const ROUTER_ADDRESS = { 
  [ChainId.OASIS] : '0x78577Ae67bC03CFF9044dd4B2db8528A0Eed573c',
  [ChainId.OASIS_TESTNET] : '0xb53A147F5aaf346a2ed543273131f1c15bA3dbB8',
  [ChainId.GANASH]: '0x050e0040F9696b1EEc09D3f6eccDeA598b2F38BE'
}

const LIZ_ADDRESS = '0x6a977d5f48D57eF125C98469D511Ef4E0cE44E10'

export const OASIS_CHAIN_PARAMS = {
  chainId: '0xa516', // A 0x-prefixed hexadecimal chainId
  chainName: 'Oasis Emerald',
  nativeCurrency: {
    name: 'Oasis',
    symbol: 'ROSE',
    decimals: 18
  },
  rpcUrls: ['https://emerald.oasis.dev'],
  blockExplorerUrls: ['https://explorer.emerald.oasis.dev//']
}

export const WHITELIST = {
  ['0x21c718c22d52d0f3a789b752d4c2fd5908a8a733'.toLowerCase()] : true, // WROSE
  ['0xdC19A122e268128B5eE20366299fc7b5b199C8e3'.toLowerCase()] : true, // USDT (ETHEREUM)
  ['0x366EF31C8dc715cbeff5fA54Ad106dC9c25C6153'.toLowerCase()] : true, // USDT (BSC)
  ['0xE8A638b3B7565Ee7c5eb9755E58552aFc87b94DD'.toLowerCase()] : true, // USDC
  ['0xa1E73c01E0cF7930F5e91CB291031739FE5Ad6C2'.toLowerCase()] : true, // UST
  ['0x3cA9dbbb1C64b5AbCb35B283441E817129c15544'.toLowerCase()] : true, // DAI
  ['0xd43ce0aa2a29DCb75bDb83085703dc589DE6C7eb'.toLowerCase()] : true, // WBTC
  ['0x3223f17957Ba502cbe71401D55A0DB26E5F7c68F'.toLowerCase()] : true, // WETH
  ['0x4F43717B20ae319Aa50BC5B2349B93af5f7Ac823'.toLowerCase()] : true, // LUNA
  ['0xd17dDAC91670274F7ba1590a06EcA0f2FD2b12bc'.toLowerCase()] : true, // SOL
  ['0xd79Ef9A91b56c690C7b80570a3c060678667f469'.toLowerCase()] : true, // WBNB
  ['0x51f712845360FB0645a9d893d0f3D73826FFB4bD'.toLowerCase()] : true, // LHODL
  [LIZ_ADDRESS.toLowerCase()] : true, // LIZ
}

export async function getReflectTokens(){
  const reflect_tokens = 
  await fetch('https://raw.githubusercontent.com/Lizard-Exchange/reflect-tokens/master/blockchains/oasis/tokenlist.json')
  .then(res=>res.json())

  return reflect_tokens.map((token:any)=> token.address.toLowerCase()) as string[]
}

export { PRELOADED_PROPOSALS } from './proposals'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const DAI = new Token(ChainId.OASIS, '0x3cA9dbbb1C64b5AbCb35B283441E817129c15544', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.OASIS, '0xE8A638b3B7565Ee7c5eb9755E58552aFc87b94DD', 6, 'USDC', 'USDC')
export const USDT = new Token(ChainId.OASIS, '0xdC19A122e268128B5eE20366299fc7b5b199C8e3', 6, 'USDT', 'Tether USD')
export const USDT_TESTNET = new Token(ChainId.OASIS_TESTNET, '0xC584fFD011e16A10fd8329853B9B8DE6E0313AD9', 6, 'USDT', 'Tether USD')
// export const COMP = new Token(ChainId.OASIS, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
// export const MKR = new Token(ChainId.OASIS, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
// export const AMPL = new Token(ChainId.OASIS, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
export const WBTC = new Token(ChainId.OASIS, '0xd43ce0aa2a29DCb75bDb83085703dc589DE6C7eb', 8, 'WBTC', 'Wrapped BTC')
export const ETH = new Token(ChainId.OASIS, '0x3223f17957Ba502cbe71401D55A0DB26E5F7c68F', 18, 'WETH', 'Wrapped Ether')

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

const LIZ_ADDRESS_TESTNET = '0x55e25a5CaeE51f8C3AE4c0bC3bbFB313740279f2'
const LIZ_ADDRESS_GANASH = '0x0bBda2B3b016844982678845647d09454813D5f5'
export const LIZ: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, LIZ_ADDRESS, 18, 'LIZ', 'Lizard'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, LIZ_ADDRESS, 18, 'LIZ', 'Lizard'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, LIZ_ADDRESS, 18, 'LIZ', 'Lizard'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, LIZ_ADDRESS, 18, 'LIZ', 'Lizard'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, LIZ_ADDRESS, 18, 'LIZ', 'Lizard'),
  [ChainId.OASIS]: new Token(ChainId.OASIS, LIZ_ADDRESS, 18, 'LIZ', 'Lizard'),
  [ChainId.OASIS_TESTNET]: new Token(ChainId.OASIS_TESTNET, LIZ_ADDRESS_TESTNET, 18, 'LIZ', 'Lizard'),
  [ChainId.GANASH]: new Token(ChainId.GANASH, LIZ_ADDRESS_GANASH, 18, 'LIZ', 'Lizard')
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [LIZ_ADDRESS]: 'LIZ',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock',
  [LIZ_ADDRESS_TESTNET]: 'LIZ'
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.OASIS]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.OASIS]: [WETH[ChainId.OASIS]],
  [ChainId.OASIS_TESTNET]: [WETH[ChainId.OASIS_TESTNET]],
  [ChainId.GANASH]: [WETH[ChainId.GANASH]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.OASIS]: [...WETH_ONLY[ChainId.OASIS], DAI, USDC, USDT, ETH, WBTC],
  [ChainId.OASIS_TESTNET]: [...WETH_ONLY[ChainId.OASIS_TESTNET], USDT_TESTNET]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.OASIS]: {
    // [AMPL.address]: [DAI, WETH[ChainId.OASIS]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.OASIS]: [...WETH_ONLY[ChainId.OASIS], DAI, USDC, USDT, ETH, WBTC],
  [ChainId.OASIS_TESTNET]: [...WETH_ONLY[ChainId.OASIS_TESTNET], USDT_TESTNET]	
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.OASIS]: [...WETH_ONLY[ChainId.OASIS], DAI, USDC, USDT, ETH, WBTC],
  [ChainId.OASIS_TESTNET]: [...WETH_ONLY[ChainId.OASIS_TESTNET], USDT_TESTNET]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.OASIS]: [
    [
      new Token(ChainId.OASIS, '0x3cA9dbbb1C64b5AbCb35B283441E817129c15544', 8, 'DAI', 'Dai'),
      new Token(ChainId.OASIS, '0xE8A638b3B7565Ee7c5eb9755E58552aFc87b94DD', 8, 'USDC', 'USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  UAUTH: {
    connector: uauth,
    name: 'Unstoppable Domains',
    iconName: 'unstoppable.png',
    description: 'Unstoppable domains.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008'
]
