import { ChainId } from '@cryptoguysclub/lizardsdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.OASIS]: '0xCcd7D12fAF86ae0b2829274cD2Dc960C40766947',
  [ChainId.OASIS_TESTNET]: '0xf98Af71b67fb1eCE1B869D80e0E4CA97ef23C912',
  [ChainId.GANASH]: '0x541381e645Be17764Cf624b7CfCB18FD54626403'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
